export const DEFAULT = {
  title: "Pengembangan Kompetensi Profesional Berbasis AI - goKampus",
  description: "Tingkatkan kompetensi profesional Anda dengan bertanya dan berlatih pada AI interaktif 24/7 yang didukung lebih dari 1.000 kursus online dari perusahaan, universitas, dan trainer ternama.",
  themeColor: "#551FC1",
  url: "https://www.gokampus.com/",
  image: "/meta-image.png",

  // for app (PWA)
  appTitle: "goKampus",
  type: "article",
};

export const BLUEBIRD = {
  title:
    "Portal BirdKembang – Akses Ratusan Kursus Terbaik dari Perusahaan dan Trainer Ternama Dunia",
  description: "On-demand streaming platform for continuous learning",
  themeColor: "#551FC1",
  url: "https://bluebird.gokampus.com/",
  image: "/icon-default.png",

  // for app (PWA)
  appTitle: "goKampus",
  type: "article",
};

export const DEFAULTBUSINESS = {
  title: "Dapatkan Akses 1.000+ Kursus di LMS/HRIS Perusahaan Anda",
  description: "On-demand streaming platform for continuous learning",
  themeColor: "#551FC1",
  url: "https://business.gokampus.com/",
  image: "/icon-default.png",

  // for app (PWA)
  appTitle: "goKampus Business",
  type: "website",
};

export const DEFAULTGATE = {
  title: "Dapatkan Akses 1.000+ Kursus di LMS/HRIS Perusahaan Anda",
  description: "On-demand streaming platform for continuous learning",
  themeColor: "#551FC1",
  url: "https://gate.gokampus.com/",
  image: "https://gate.gokampus.com/icon-default-business.png",

  // for app (PWA)
  appTitle: "goKampus Business",
  type: "website",
};

// Desa-desa
export const DEFAULTGATEMALUKU = {
  title:
    "goKampus Maluku - Belajar Berbagai Kursus Pengembangan Diri dan Tren Industri Terkini",
  description:
    "Platform Kursus Online dari Institusi, Perusahaan, dan Universitas Terkemuka",
  themeColor: "#551FC1",
  url: "https://maluku-gate.gokampus.com/",
  image: "https://maluku-gate.gokampus.com/maluku/icon-512x512.png",

  // for app (PWA)
  appTitle: "goKampus Maluku",
  type: "website",
};

export const DEFAULTGATETRENGGALEK = {
  title:
    "goKampus Trenggalek - Belajar Berbagai Kursus Pengembangan Diri dan Tren Industri Terkini",
  description:
    "Platform Kursus Online dari Institusi, Perusahaan, dan Universitas Terkemuka",
  themeColor: "#551FC1",
  url: "https://trenggalekkab.gokampus.com/",
  image: "https://trenggalekkab.gokampus.com/trenggalek/icon-512x512.png",

  // for app (PWA)
  appTitle: "goKampus Trenggalek",
  type: "website",
};

export const DEFAULTGATEPESAWARAN = {
  title:
    "goKampus Pesawaran - Belajar Berbagai Kursus Pengembangan Diri dan Tren Industri Terkini",
  description:
    "Platform Kursus Online dari Institusi, Perusahaan, dan Universitas Terkemuka",
  themeColor: "#551FC1",
  url: "https://pesawarankab.gokampus.com/",
  image: "https://pesawarankab.gokampus.com/pesawaran/icon-512x512.png",

  // for app (PWA)
  appTitle: "goKampus Pesawaran",
  type: "website",
};

export const DEFAULT_MUARO_JAMBI = {
  title:
    "goKampus Muaro Jambi - Belajar Berbagai Kursus Pengembangan Diri dan Tren Industri Terkini",
  description:
    "Platform Kursus Online dari Institusi, Perusahaan, dan Universitas Terkemuka",
  themeColor: "#551FC1",
  url: "https://muarojambikab.gokampus.com/",
  image: "https://muarojambikab.gokampus.com/muaro-jambi/icon-512x512.png",

  // for app (PWA)
  appTitle: "goKampus Muaro Jambi",
  type: "website",
};

export const DEFAULT_JAMBI = {
  title:
    "goKampus Jambi - Belajar Berbagai Kursus Pengembangan Diri dan Tren Industri Terkini",
  description:
    "Platform Kursus Online dari Institusi, Perusahaan, dan Universitas Terkemuka",
  themeColor: "#551FC1",
  url: "https://jambikota.gokampus.com/",
  image: "https://jambikota.gokampus.com/jambi/icon-512x512.png",

  // for app (PWA)
  appTitle: "goKampus Jambi",
  type: "website",
};
